import { parseMessage, parseMessageHeader } from "./messages/message";

export default function parser() {
  return {
    parse: (input: string) => {
      const result = parse(input);
      if (result.status === 'success') {
        return result.value;
      } else {
        throw new Error(result.message);
      }
    }
  };
}

export function parse(input: string) {
  const lines = input.split("'");
  try {
    const messageHeader = parseMessageHeader(lines[0]);
    const message = parseMessage(messageHeader.messageType, lines);
    return {
      status: "success",
      value: message,
    };
  } catch (error) {
    return {
      status: "error",
      message: "Invalid message header",
    };
  }
}