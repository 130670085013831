import InventoryReportMessage, {
  convertInventoryReportToCsv,
} from "./messages/inventoryReportMessage";
import InvoiceMessage, { convertInvoiceToCsv } from "./messages/invoiceMessage";
import Message from "./messages/message";
import SalesReportMessage, {
  convertSalesReportToCsv,
} from "./messages/salesReportMessage";

const converter = {
  convertToCsv,
};
export default converter;

function convertToCsv(input: Message[]) {
  const messageType = input[0]?.messageType ?? "";
  switch (messageType) {
    case "SLSRPT":
      return convertSalesReportToCsv(input as SalesReportMessage[]);
    case "INVOIC":
      return convertInvoiceToCsv(input as InvoiceMessage[]);
    case "INVRPT":
      return convertInventoryReportToCsv(input as InventoryReportMessage[]);
    default:
      return "";
  }
}
