import { parseDateSegment } from "../segments/date";
import { parseLocationSegment } from "../segments/location";
import { parseQuantitySegment } from "../segments/quantity";
import Message, { parseMessageHeader } from "./message";

export function parseSalesReport(lines: string[]): SalesReportMessage[] {
  const messages: SalesReportMessage[] = [];

  let currentMessage: SalesReportMessage = {
    messageNumber: "",
    messageType: "",
    messageVersion: "",
    date: new Date(),
    sales: [],
  };

  let currentSale: SalesItem = {
    reportNumber: "",
    lineNumber: "0",
    date: new Date(),
    locationCode: "",
    productCode: "",
    quantity: 0,
    returnedQuantity: 0,
  };

  for (let i = 0; i < lines.length; i++) {
    const segment = lines[i].trim();
    const nextSegment = lines[i + 1];
    const segmentType = segment.split("+")[0];
    const nextSegmentType = nextSegment ? nextSegment.split("+")[0] : "";

    switch (segmentType) {
      case "LOC": 
        const locationSegment = parseLocationSegment(segment);
        if (locationSegment.locationType === "sales") {
          currentSale.locationCode = locationSegment.code;
        }
        break;
      case "DTM":
        const dateSegment = parseDateSegment(segment);
        if (dateSegment.dateType === "document") {
          currentMessage.date = dateSegment.date;
        };
        if (dateSegment.dateType === "sales") {
          currentSale.date = dateSegment.date;
        };
        break;
      case "LIN":
        const lineSegment = segment.split("+");
        currentSale = {
          ...currentSale,
          lineNumber: lineSegment[1],
          productCode: lineSegment[3].split(":")[0], 
          quantity: 0,
          returnedQuantity: 0,
        };
        break;
      case "QTY":
        const quantitySegment = parseQuantitySegment(segment);
        if (quantitySegment.quantityType === "sales") {
          currentSale.quantity = quantitySegment.quantity;
        }
        if (quantitySegment.quantityType === "returned") {
          currentSale.returnedQuantity = quantitySegment.quantity;
        }
        if (nextSegmentType !== "QTY" && !isEmptyItem(currentSale)) {
          const newSale = {...currentSale};
          currentMessage.sales.push(newSale);
        }
        break;
      case "UNT":
        // end of message
        const newMessage = {...currentMessage};
        messages.push(newMessage);
        break;
      case "UNH":
        // start of message
        const { messageType, messageNumber, messageVersion } = parseMessageHeader(segment);
        currentMessage = {
          messageNumber,
          messageType,
          messageVersion,
          sales: [],
        };
        currentSale.reportNumber = messageNumber;
        break;
    }
  };
  return messages;
}

const isEmptyItem = (item: SalesItem) => {
  return item.productCode === "" && item.quantity === 0 && item.returnedQuantity === 0;
}

export const convertSalesReportToCsv = (messages: SalesReportMessage[]) => {
  const headers = ["id,salesDate,locationCode,productCode,quantity,returnedQuantity"];
  const csvLines = headers.concat(messages.map(message => {
    return message.sales.map(sale => {
      return `${sale.reportNumber}#${sale.lineNumber},${sale.date.toISOString().split("T")[0]},${sale.locationCode},${sale.productCode},${sale.quantity},${sale.returnedQuantity}`;
    }).join("\n");
  })).join("\n");
  return csvLines;
}

export default interface SalesReportMessage extends Message {
  sales: SalesItem[];
}

export interface SalesItem {
  reportNumber: string;

  locationCode: string;
  date: Date;

  lineNumber: string;
  productCode: string;

  quantity: number;
  returnedQuantity?: number;
  price?: number;
}