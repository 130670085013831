import Segment from "./segment";

export const parseLocationSegment = (segment: string): LocationSegment => {
  const elements = segment.split("+");
  const info = elements[2].split(":");
  return {
    segmentType: "LOC",
    locationType: getLocationType(elements[1]),
    code: info[0] || "undefined",
    name: info[3],
  };
}

const getLocationType = (code: string): LocationSegmentType => {
  switch (code) {
    case "162":
      return "sales";
    default: 
      return "unknown";
  }
}

interface LocationSegment extends Segment {
  segmentType: "LOC";
  locationType: LocationSegmentType;
  code: string;
  name?: string;
}

type LocationSegmentType = "sales" | "delivery"
  | "payment" | "departure" | "termsDelivery" 
  | "destination" | "locationOfGoods" 
  | "warehouse" | "countryOfOrigin" | "unknown";