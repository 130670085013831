import { parseInventoryReport } from "./inventoryReportMessage";
import { parseInvoice } from "./invoiceMessage";
import { parseSalesReport } from "./salesReportMessage";

export default interface Message {
  messageNumber: string;
  messageType: string;
  messageVersion?: string;
  date?: Date;
}

export const parseMessage = (messageType: string, lines: string[]) => {
  const parsers: { [key: string]: Message[] } = {
    SLSRPT: parseSalesReport(lines),
    INVOIC: parseInvoice(lines),
    INVRPT: parseInventoryReport(lines),
  };
  if (!parsers.hasOwnProperty(messageType)) {
    throw new Error(`Unknown message type: ${messageType}`);
  }
  return parsers[messageType];
};

export function parseMessageHeader(line: string) {
  const segments = line.split("+");
  if (segments[0] !== "UNH") {
    throw new Error("Invalid message header");
  }
  const elements = segments[2].split(":");
  return {
    messageNumber: segments[1],
    messageType: elements[0],
    messageVersion: elements.slice(1, 5).join("-"),
  };
}
