import { parseDateSegment } from "../segments/date";
import { parseQuantitySegment } from "../segments/quantity";
import Message, { parseMessageHeader } from "./message";

export default interface InventoryReportMessage extends Message {
  reportNumber: string;
  locationCode: string;
  date: Date;
  inventory: InventoryItem[];
}

export interface InventoryItem {
  lineNumber: string;
  productCode: string;
  quantity: number;
}

const initialMessage: InventoryReportMessage = {
  messageType: "",
  messageVersion: "",
  messageNumber: "",
  reportNumber: "",
  locationCode: "",
  date: new Date(),
  inventory: [],
};

export function parseInventoryReport(
  lines: string[]
): InventoryReportMessage[] {
  const messages: InventoryReportMessage[] = [];

  let currentMessage = { ...initialMessage };
  let currentItem: InventoryItem = {
    lineNumber: "0",
    productCode: "",
    quantity: 0,
  };

  lines.forEach((line, i) => {
    const segment = line.trim();
    const nextSegment = lines[i + 1];
    const segmentType = segment.split("+")[0];
    const nextSegmentType = nextSegment ? nextSegment.split("+")[0] : "";

    switch (segmentType) {
      case "NAD":
        const nadSegment = segment.split("+");
        // GY = Inventory reporting party
        if (nadSegment[1] === "GY") {
          currentMessage.locationCode = nadSegment[2].split(":")[0];
        }
        break;
      case "DTM":
        const dateSegment = parseDateSegment(segment);
        if (dateSegment.dateType === "document") {
          currentMessage.date = dateSegment.date;
        }
        break;
      case "LIN":
        const lineSegment = segment.split("+");
        currentItem = {
          ...currentItem,
          lineNumber: lineSegment[1],
          productCode: lineSegment[3].split(":")[0],
          quantity: 0,
        };
        break;
      case "QTY":
        const quantitySegment = parseQuantitySegment(segment);
        if (quantitySegment.quantityType === "stock") {
          currentItem.quantity = quantitySegment.quantity;
        }
        if (nextSegmentType !== "QTY" && !isEmptyItem(currentItem)) {
          const newItem = { ...currentItem };
          currentMessage.inventory.push(newItem);
        }
        break;
      case "UNT":
        // end of message
        const newMessage = { ...currentMessage };
        messages.push(newMessage);
        break;
      case "UNH":
        // start of message
        const { messageType, messageNumber, messageVersion } =
          parseMessageHeader(segment);
        currentMessage = {
          ...initialMessage,
          messageNumber,
          messageType,
          messageVersion,
          reportNumber: messageNumber,
          inventory: [],
        };
        break;
    }
  });
  return messages;
}

const isEmptyItem = (item: InventoryItem) => {
  return item.productCode === "" && item.quantity === 0;
};

export const convertInventoryReportToCsv = (
  messages: InventoryReportMessage[]
) => {
  const headers = ["id,inventoryDate,locationCode,productCode,quantity"];
  const csvLines = headers
    .concat(
      messages.map((message) => {
        return message.inventory
          .map((inventory) => {
            return `${message.reportNumber}#${inventory.lineNumber},${
              message.date.toISOString().split("T")[0]
            },${message.locationCode},${inventory.productCode},${
              inventory.quantity
            }`;
          })
          .join("\n");
      })
    )
    .join("\n");
  return csvLines;
};
