import converter from "../edifact/converter";
import Message from "../edifact/messages/message";

export default function DonwloadCsvButton(props: {
  data?: Message[];
  filename?: string;
}) {
  const messages = props.data || undefined;
  if (!messages) {
    return null;
  }
  const csvString = converter.convertToCsv(messages) || "";

  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  return (
    <a href={url} download={props.filename + ".csv" || "csv-export.csv"}>
      Download CSV
    </a>
  );
}