import Segment from "./segment";

export const parseMonetaryAmountSegment = (
  segment: string
): MonetaryAmountSegment => {
  const elements = segment.split("+")[1].split(":");
  return {
    segmentType: "MOA",
    amountType: getAmountType(elements[0]),
    amount: parseFloat(elements[1]),
  };
};

const getAmountType = (code: string): QuantitySegmentType => {
  switch (code) {
    case "66":
      return "lineItem";
    case "79":
      return "totalLineItems";
    case "98":
      return "original";
    case "139":
      return "totalPayment";
    case "176":
      return "totalTaxes";
    case "204":
      return "allowance";
    case "259":
      return "charges";
    default:
      return "unknown";
  }
};

interface MonetaryAmountSegment extends Segment {
  segmentType: "MOA";
  amount: number;
  amountType: QuantitySegmentType;
}

type QuantitySegmentType =
  | "lineItem"
  | "allowance"
  | "totalPayment"
  | "totalTaxes"
  | "totalLineItems"
  | "original"
  | "charges"
  | "unknown";
