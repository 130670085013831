import Segment from "./segment";

export const parseDateSegment = (segment: string): DateSegment => {
  const elements = segment.split("+")[1].split(":");
  return {
    segmentType: "DTM",
    dateType: getDateType(elements[0]),
    date: getDate(elements[1], elements[2]),
  };
};

const getDateType = (code: string): DateSegmentType => {
  switch (code) {
    case "137":
      return "document";
    case "356":
      return "sales";
    default:
      return "unknown";
  }
};

const getDate = (date: string, formatCode: string = "102") => {
  switch (formatCode) {
    case "102": // "YYYYMMDD"
      return new Date(
        [date.slice(0, 4), date.slice(4, 6), date.slice(6, 8)].join("-")
      );
    case "203": // "YYYYMMDDHHMM"
      return new Date(
        [date.slice(0, 4), date.slice(4, 6), date.slice(6, 8)].join("-") +
          "T" +
          [date.slice(8, 10), date.slice(10, 12)].join(":")
      );
    default:
      return new Date();
  }
};

interface DateSegment extends Segment {
  segmentType: "DTM";
  date: Date;
  dateType: DateSegmentType;
}

type DateSegmentType = "invoice" | "order" | "document" | "sales" | "unknown";
