import React, { useRef, useState } from 'react';
import './App.css';
import { parse } from './edifact/parser';
import DonwloadCsvButton from './download/DonwloadCsvButton';
import Message from './edifact/messages/message';

function App() {
  const fileName = useRef("");
  const [text, setText] = useState<string | undefined>();

  const [messages, setMessages] = useState<Message[] | undefined>();

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <main>
        <h1>Web EDIFACT to CSV converter</h1>
        <p>Drag and drop file below. Messages supported: INVRPT, SLSRPT and INVOIC.</p>
        {Boolean(text) ? (
          <>
            <DonwloadCsvButton data={messages} filename={fileName.current} />
            <p className="file-result">{text}</p>
            <button onClick={() => setText(undefined)}>Clear</button>
          </>
        ) : (
          <input className="drop-file" type="file" accept=".edi" onChange={(event) => {
            if (event.target.files) {
              const file = event.target.files[0];
              fileName.current = file.name;
              const reader = new FileReader();
              reader.onload = (e) => {
                // console.log("File contents: ", e.target?.result);
                if (e.target?.result) {
                  // console.log("Result:", parse(e.target.result.toString()));
                  // setText(e.target.result.toString());
                  const result = parse(e.target.result.toString());
                  const info = {
                    status: result.status,
                    count: result.value?.length,
                    messages: result.value?.map((message) => ({
                      type: message.messageType,
                      message: message.messageNumber,
                      date: message.date?.toISOString().split("T")[0],
                    })),
                  };
                  console.log("Info:", result);
                  setText(JSON.stringify(info, null, 2));
                  setMessages(result.value);
                }
              }
              reader.readAsText(file);
            }
          }} onDragEnter={(e) => {
            e.currentTarget.classList.add("drag-over");
          }} onDragLeave={(e) => {
            e.currentTarget.classList.remove("drag-over");
          }} />
        )}
      </main>
    </div>
  );
}

export default App;
