import { parseDateSegment } from "../segments/date";
import { parseMonetaryAmountSegment } from "../segments/monetaryAmount";
import { parseQuantitySegment } from "../segments/quantity";
import Message, { parseMessageHeader } from "./message";

export default interface InvoiceMessage extends Message {
  invoiceNumber: string;
  locationCode: string;
  totalLines: number;
  lineItems: LineItem[];
  subtotal: number;
  total: number;
  original: number;
}

export interface LineItem {
  invoiceNumber: string;
  lineNumber: string;
  productCode: string;
  quantity: number;
  amount: number;
  description: string;
}

const initialMessage: InvoiceMessage = {
  messageNumber: "",
  messageType: "",
  messageVersion: "",
  date: new Date(),
  invoiceNumber: "",
  locationCode: "",
  totalLines: 0,
  lineItems: [],
  subtotal: 0,
  total: 0,
  original: 0,
};

export function parseInvoice(lines: string[]): InvoiceMessage[] {
  const messages: InvoiceMessage[] = [];
  let currentMessage = { ...initialMessage };
  let currentLineItem: LineItem = {
    invoiceNumber: "",
    lineNumber: "0",
    productCode: "",
    quantity: 0,
    amount: 0,
    description: "",
  };

  for (let i = 0; i < lines.length; i++) {
    const segment = lines[i].trim();
    const nextSegment = lines[i + 1];
    const segmentType = segment.split("+")[0];
    const nextSegmentType = nextSegment ? nextSegment.split("+")[0] : "";

    switch (segmentType) {
      case "DTM":
        const dateSegment = parseDateSegment(segment);
        if (dateSegment.dateType === "document") {
          currentMessage.date = dateSegment.date;
        }
        break;
      case "LIN":
        const lineSegment = segment.split("+");
        currentLineItem = {
          ...currentLineItem,
          lineNumber: lineSegment[1],
          productCode: lineSegment[3].split(":")[0],
          description: "",
          quantity: 0,
          amount: 0,
        };
        break;
      case "QTY":
        const quantitySegment = parseQuantitySegment(segment);
        if (quantitySegment.quantityType === "invoiced") {
          currentLineItem.quantity = quantitySegment.quantity;
        }
        break;
      case "MOA":
        const amountSegment = parseMonetaryAmountSegment(segment);
        if (amountSegment.amountType === "lineItem") {
          currentLineItem.amount = amountSegment.amount;
        }
        if (amountSegment.amountType === "totalLineItems") {
          currentMessage.subtotal = amountSegment.amount;
        }
        if (amountSegment.amountType === "totalPayment") {
          currentMessage.total = amountSegment.amount;
        }
        if (amountSegment.amountType === "original") {
          currentMessage.original = amountSegment.amount;
        }
        if (["LIN", "UNS"].includes(nextSegmentType)) {
          currentMessage.lineItems.push({ ...currentLineItem });
        }
        break;
      case "IMD":
        const imdSegment = segment.split("+");
        if (imdSegment[1] === "F") {
          currentLineItem.description += `${imdSegment[3]
            .split(":")
            .join("")} `;
        }
        break;
      case "BGM":
        const bgmSegment = segment.split("+");
        if (bgmSegment[1] === "380") {
          currentMessage = {
            ...currentMessage,
            invoiceNumber: bgmSegment[2],
          };
          currentLineItem.invoiceNumber = bgmSegment[2];
        }
        break;
      case "CNT":
        const cntSegment = segment.split("+")[1].split(":");
        if (cntSegment[0] === "2") {
          currentMessage.totalLines = parseInt(cntSegment[1]);
        }
        break;
      case "NAD":
        const nadSegment = segment.split("+");
        // SU = supplier; BY = buyer; DP = delivery party; IV = invoicee;
        if (nadSegment[1] === "BY") {
          currentMessage.locationCode = nadSegment[2].split(":")[0];
        }
        break;
      case "UNT":
        // end of message
        const newMessage = { ...currentMessage };
        messages.push(newMessage);
        break;
      case "UNH":
        // start of message
        const { messageType, messageNumber, messageVersion } =
          parseMessageHeader(segment);
        currentMessage = {
          ...initialMessage,
          lineItems: [],
          messageNumber,
          messageType,
          messageVersion,
        };
        break;
    }
  }

  return messages;
}

export const convertInvoiceToCsv = (messages: InvoiceMessage[]) => {
  const headers = [
    "id,productCode,description,quantity,amount,invoiceNumber,invoiceDate,locationCode",
  ];
  const csvLines = headers
    .concat(
      messages.map((message) => {
        return message.lineItems
          .map((lineItem) => {
            return `${lineItem.invoiceNumber}#${lineItem.lineNumber},${
              lineItem.productCode
            },${lineItem.description},${lineItem.quantity},${lineItem.amount},${
              lineItem.invoiceNumber
            },${message.date?.toISOString().split("T")[0]},${
              message.locationCode
            }`;
          })
          .join("\n");
      })
    )
    .join("\n");
  return csvLines;
};
