import Segment from "./segment";

export const parseQuantitySegment = (segment: string): QuantitySegment => {
  const elements = segment.split("+")[1].split(":");
  return {
    segmentType: "QTY",
    quantityType: getQuantityType(elements[0]),
    quantity: parseInt(elements[1]),
  };
};

const getQuantityType = (code: string): QuantitySegmentType => {
  switch (code) {
    case "47":
      return "invoiced";
    case "145":
      return "stock";
    case "153":
      return "sales";
    case "77E":
    case "210":
      return "returned";
    default:
      return "unknown";
  }
};

interface QuantitySegment extends Segment {
  segmentType: "QTY";
  quantity: number;
  quantityType: QuantitySegmentType;
}

type QuantitySegmentType =
  | "sales"
  | "returned"
  | "invoiced"
  | "stock"
  | "unknown";
